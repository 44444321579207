const React = require("react")
const { PrismicPreviewProvider } = require('gatsby-plugin-prismic-previews')
require ('gatsby-plugin-prismic-previews/dist/styles.css')

exports.onRouteUpdate = function({ location }) {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq("track", "ViewContent")
  }

  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.ga(`set`, `page`, pagePath)
    window.ga(`send`, `pageview`)
  }

  if (process.env.NODE_ENV === `production` && typeof ga === `function`) {
    // Minimum delay for reactHelmet's requestAnimationFrame
    setTimeout(sendPageView, 32)
  }

}

exports.wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
  )
}
