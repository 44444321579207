module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"a5be48936f893ac8b0ca6d9797edc129","trackPageViewsAs":"Website View","pageViews":"all"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bizyness-website","promptForAccessToken":true,"apiEndpoint":"https://bizyness-website.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Logiciel de comptabilité et de gestion pour les entrepreneurs","short_name":"Bizyness","description":"Vous recherchez une solution simple pour gérer votre comptabilité ? Bizyness vous permet d'envoyer des factures et devis à vos clients en quelques clics. Que vous soyez auto-entrepreneur, gérant de SARL, de SASU ou autre, Bizyness s'adapte automatiquement à votre statut. Essayez gratuitement pendant 30 jours !","start_url":"/","background_color":"#ffbd1e","theme_color":"#ffbd1e","display":"standalone","icon":"src/assets/images/bizyness-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"63b752d171fdcf3f3691f80accbda6b2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T52DQD6","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
